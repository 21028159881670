import React from "react";
import Icon from "../../ui/Icon";
import classnames from "classnames";

interface BigAudioPlayButtonProps {
  hidden: boolean;
  onClick: () => void;
}

const BigAudioPlayButton: React.FC<BigAudioPlayButtonProps> = ({
  hidden,
  onClick,
}) => (
  <div
    className={classnames(
      "cursor-pointer absolute top-0 left-0 w-full h-full big-play-button",
      { hidden }
    )}
    onClick={onClick}
    data-action={"play"}
  >
    <Icon type={"play"} size="large" />
  </div>
);

export default BigAudioPlayButton;
