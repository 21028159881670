import { Controller } from "stimulus";

const yearsToMonths = (years) => {
  return years * 12;
};

const ageRangeMapping = {
  "2-6": [yearsToMonths(2), yearsToMonths(6)],
  "6-9": [yearsToMonths(6), yearsToMonths(9)],
  "9-13": [yearsToMonths(9), yearsToMonths(13)]
};

export default class ProfileSettingsController extends Controller {
  static targets = [
    "ageRangeSelector",
    "ageRange",
    "minAgeInMonths",
    "minAgeInMonthsWrapper",
    "maxAgeInMonths",
    "maxAgeInMonthsWrapper",
    "contentLanguage",
  ];

  minAgeInMonthsChange() {
    const values = this.rangeValues();

    if (values.min && values.max && values.max < values.min) {
      this.maxAgeInMonthsTarget.value = values.min;
    }
  }

  maxAgeInMonthsChange() {
    const values = this.rangeValues();

    if (values.min && values.max && values.min > values.max) {
      this.minAgeInMonthsTarget.value = values.max;
    }
  }

  ageRangeChange() {
    const value = this.ageRangeTarget.value;
    if (value === "") {
      this.minAgeInMonthsTarget.value = "";
      this.maxAgeInMonthsTarget.value = "";

      return;
    }
    const [minAgeInMonths, maxAgeInMonths] = ageRangeMapping[value];
    this.minAgeInMonthsTarget.value = minAgeInMonths;
    this.maxAgeInMonthsTarget.value = maxAgeInMonths;
  }

  rangeValues() {
    return {
      min: this.minAgeInMonthsTarget.value
        ? parseInt(this.minAgeInMonthsTarget.value)
        : null,
      max: this.maxAgeInMonthsTarget.value
        ? parseInt(this.maxAgeInMonthsTarget.value)
        : null,
    };
  }
}
