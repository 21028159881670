import * as React from "react";
import * as _ from "lodash";
import { NavState } from "./services/navigationBehavior";
import screenfull, { Screenfull } from "screenfull";
import { progress, Progress } from "./services/progress";
import Book, { BookType } from "./Book";
import HTMLFlipBook from "react-pageflip";
import FlipControls from "./animated_reader_controls/FlipControls";
import BottomToolbar from "./animated_reader_controls/BottomToolbar";
import * as LazyPageLoader from "./services/lazyPageLoader";
import Loader from "react-loader-spinner";
import LazyPage from "./LazyPage";
import { isIOS, isMobileOnly, isTablet } from "react-device-detect";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Howl } from "howler";
import parser from "subtitles-parser";
import BigAudioPlayButton from "./animated_reader_controls/BigAudioPlayButton";

function getOrientation() {
  if (window.orientation !== undefined) {
    return window.orientation == 90 || window.orientation == -90
      ? "landscape"
      : "portrait";
  }

  return window.innerHeight > window.innerWidth ? "portrait" : "landscape";
}

interface AnimatedFlipReaderProps {
  epubFileURL: string;
  audioFileURL: string;
  subtitleURL: string;
  initialPage: number;
  book: Book;
  pageImages: Array<object>;
  epubDirectory: String;

  onFinish: (currentPage: number) => void;
  onNavigate: (currentPage: number, readingTime?: number) => void;
}

enum LastPageFlipHandler {
  "book",
  "slide",
  "page_click",
}

enum FlipEventType {
  "next",
  "prev",
  "slide",
  "restart",
  "user_fold",
  "flipping",
}

interface ZoomableProps {
  children: JSX.Element;
  disabled: boolean;
  zoom?: number;
}

const FlipBook = React.memo(
  React.forwardRef(
    ({ bookDb, pageLoaded, epubDirectory, ...rest }: any, ref) => {
      return (
        <HTMLFlipBook ref={ref} {...rest}>
          {bookDb.map((v: any, i: number) => (
            <div className="w-full h-full pointer-events-none bg-white" key={i}>
              <LazyPage
                ref={v["lazyRef"]}
                page={v}
                imgSrc={epubDirectory + v.href}
                imageLoaded={pageLoaded}
              />
            </div>
          ))}
        </HTMLFlipBook>
      );
    }
  )
);

function Zoomable({ children, disabled }: ZoomableProps) {
  if (disabled) {
    // the fit-content prevents hard-cover-turns to glitch to the
    // top, the inner w-screen h-auto then make sure positioning
    // is correct again...
    return (
      <div style={{ width: "fit-content", height: "fit-content" }}>
        <div className="w-screen h-auto">{children}</div>
      </div>
    );
  }

  return (
    <TransformWrapper pan={{ paddingSize: 5 }}>
      <TransformComponent>
        <div className="w-screen h-auto">{children}</div>
      </TransformComponent>
    </TransformWrapper>
  );
}

interface AnimatedFlipReaderState {
  currentPage: number;
  fullscreen: boolean;
  book: Book;
  coverHeight: number;
  coverWidth: number;
  loaded: boolean;
  bookDb: Array<any>;
  flipEvent: FlipEventType;
  lastPageFlipHandler: LastPageFlipHandler;
  loadedPages: Array<number>;
  orientation: string;
  playing: boolean;
  totalDuration?: number;
  firstPlay: boolean;
}

interface SubtitleTrack {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
}
export default class AnimatedFlipReader extends React.PureComponent<
  AnimatedFlipReaderProps,
  AnimatedFlipReaderState
> {
  private container: React.RefObject<HTMLDivElement>;
  private fbook: any;
  private sound?: Howl;
  private timer?: number;
  private currentAudioPage: number = -1;
  private subtitles: Array<SubtitleTrack> = [];
  private currentPlaying?: number;
  private readingTime: number = 0;
  private previousScreenOrientation: string;

  constructor(props: AnimatedFlipReaderProps) {
    super(props);
    this.fbook = React.createRef();
    this.container = React.createRef();

    this.previousScreenOrientation = getOrientation();

    const bdb = this.props.pageImages.map((img: any, i: number) => {
      img["lazyRef"] = React.createRef();
      img["loaded"] = false;
      img["page"] = i;
      return img;
    });

    this.state = {
      currentPage:
        props.initialPage === 1 
          ? 0
          : props.initialPage,
      fullscreen: false,
      book: props.book,
      coverHeight: 0,
      coverWidth: 0,
      loaded: false,
      bookDb: bdb,
      flipEvent: FlipEventType.restart,
      lastPageFlipHandler: LastPageFlipHandler.book,
      loadedPages: [],
      orientation: getOrientation(),
      playing: false,
      firstPlay: true,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.onFlip = this.onFlip.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.pageLoaded = this.pageLoaded.bind(this);
    this.restart = this.restart.bind(this);
    this.nextPageAnim = this.nextPageAnim.bind(this);
    this.prevPageAnim = this.prevPageAnim.bind(this);
    this.slideTo = this.slideTo.bind(this);
    this.onChangeOrientation = this.onChangeOrientation.bind(this);
  }

  async componentDidMount() {
    this.previousScreenOrientation = getOrientation();

    if (this.canPlayAudio) {
      this.sound = new Howl({
        src: [this.props.audioFileURL],
        html5: true,
      });
    }

    try {
      const [image] = await Promise.all([
        LazyPageLoader.imageLoader(
          this.state.bookDb[0],
          this.props.epubDirectory
        ),
        this.loadSRTFile(),
      ]);

      this.setState({
        loaded: true,
        coverWidth: image.width,
        coverHeight: image.height,
      });

      LazyPageLoader.lazyLoader(
        this.state.currentPage,
        FlipEventType.slide,
        this.state.bookDb,
        this.state.currentPage
      );
    } catch (e) {
      // TODO: Handle error
    }

    window.addEventListener("keydown", this.onKeyDown.bind(this), false);
    window.addEventListener("orientationchange", this.onChangeOrientation);

    ["play", "pause", "stop", "end"].forEach((eventName) => {
      this.sound?.on(eventName, this.onAudioStatusChanged);
    });

    ["pause", "stop", "end"].forEach((eventName) => {
      this.sound?.on(eventName, this.onStop);
    });

    this.sound?.once("load", this.onLoadSound);
    this.sound?.once("play", this.onFirstPlay);
    this.sound?.on("play", this.onPlaying);

    if (screenfull.isEnabled) {
      (screenfull as Screenfull).on(
        "change",
        this.onScreenfullChange.bind(this)
      );
    }

    // As full screen doesn't work on iPhone at least hide the browser
    if (isIOS && isMobileOnly) {
      window.scrollTo(0, 1);
    }
  }

  componentDidUpdate(
    prevProps: AnimatedFlipReaderProps,
    prevState: AnimatedFlipReaderState
  ) {
    if (prevState.currentPage != this.state.currentPage) {
      this.props.onNavigate(this.state.currentPage, this.readingTime);
      this.readingTime = 0;
    }
  }

  componentWillUnmount() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.onScreenfullChange);
    }

    this.sound?.stop();
    this.sound?.unload();

    window.removeEventListener("keydown", this.onKeyDown, false);
    window.removeEventListener("orientationchange", this.onChangeOrientation);

    ["pause", "stop", "end"].forEach((eventName) => {
      this.sound?.off(eventName, this.onStop);
    });

    ["play", "pause", "stop", "end"].forEach((eventName) => {
      this.sound?.off(eventName, this.onAudioStatusChanged);
    });

    this.sound?.off("play", this.onPlaying);
  }

  loadSRTFile = async () => {
    if (!this.props.subtitleURL) {
      return;
    }

    const file = await fetch(this.props.subtitleURL);

    if (!file.ok) {
      return;
    }

    const text = await file.text();

    this.subtitles = parser.fromSrt(text, true);
    this.subtitles = this.subtitles.map((d) => ({
      ...d,
      startTime: d.startTime / 1000,
      endTime: d.endTime / 1000,
    }));
  };

  onLoadSound = () => {
    this.onPlay();

    this.setState({
      totalDuration: this.sound?.duration(),
    });

    // Hide big play button after 4s
    setTimeout(() => {
      this.onFirstPlay();
    }, 4000);
  };

  onFirstPlay = () => {
    this.setState({
      firstPlay: false,
    });
  };

  onAudioStatusChanged = () => {
    this.setState({ playing: this.sound?.playing() || false });
  };

  onPlaying = () => {
    this.timer = window.setInterval(() => {
      this.onPlaybackProgress(this.sound?.seek() || 0);
    }, 250);
  };

  onPlaybackProgress = (currentTime: number) => {
    if (!this.fbook.current) {
      this.onStop();
      return;
    }

    this.readingTime += 0.25;

    if (this.fbook.current.pageFlip().getState() !== "read") {
      return;
    }

    if (this.subtitles.length === 0) {
      return;
    }

    const index = this.subtitles.findIndex((s) => {
      if (s.startTime < currentTime && s.endTime > currentTime) {
        return true;
      }
      return false;
    });

    if (index === -1) {
      return;
    }

    if (this.currentAudioPage === index) {
      return;
    }

    this.currentAudioPage = index;
    this.slideTo(this.currentAudioPage);
  };

  onStop = () => {
    clearInterval(this.timer);
  };

  onPlay = () => {
    if (this.sound?.state() !== "loaded") {
      return;
    }

    if (this.sound?.playing()) {
      this.sound?.pause();
    } else {
      if (
        this.subtitles.length > 0 &&
        this.state.currentPage > 0 &&
        this.state.currentPage !== this.currentAudioPage
      ) {
        this.currentAudioPage = this.state.currentPage;
        this.sound?.seek(this.subtitles[this.state.currentPage].startTime);
        this.playAudio();
      } else {
        this.playAudio();
      }
    }
  };

  playAudio = () => {
    if (this.sound?.playing(this.currentPlaying)) {
      return;
    }

    if (this.currentPlaying) {
      this.sound?.play(this.currentPlaying);
    } else {
      this.currentPlaying = this.sound?.play();
    }
  };

  toggleFullscreen() {
    this.onFirstPlay();

    if (!screenfull.isEnabled) return;

    if (this.state.fullscreen) {
      (screenfull as Screenfull).exit();
    } else {
      this.container.current &&
        (screenfull as Screenfull).request(this.container.current);
    }
  }

  onChangeOrientation() {
    const orientation = getOrientation();
    this.setState({ orientation });
  }

  onBookChangeOrientation = () => {
    this.previousScreenOrientation = getOrientation();
    if (this.currentAudioPage === -1) {
      return;
    }
    this.slideTo(this.currentAudioPage);
  };

  onScreenfullChange() {
    this.setState({
      fullscreen: (screenfull as Screenfull).isFullscreen,
    });
  }

  onFinish() {
    this.setState({
      currentPage: 0,
    });
    this.props.onFinish(0);
  }

  onKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case "ArrowRight":
        this.nextPageAnim();
        return;
      case "Space":
      case "ArrowLeft":
        this.prevPageAnim();
        return;
    }
  }

  onFlip(e: any) {
    const currentPage = e.data;
    LazyPageLoader.lazyLoader(
      e.data,
      FlipEventType.user_fold,
      this.state.bookDb,
      this.state.currentPage
    );
    this.setState({
      lastPageFlipHandler: LastPageFlipHandler.page_click,
      currentPage,
    });

    if (this.previousScreenOrientation !== getOrientation()) {
      return;
    }

    if (this.subtitles.length > 0 && currentPage !== this.currentAudioPage) {
      this.currentAudioPage = currentPage;
      this.sound?.seek(this.subtitles[currentPage].startTime);
    }
  }

  slideTo(pageNumber: number) {
    this.onFirstPlay();

    LazyPageLoader.lazyLoader(
      pageNumber,
      FlipEventType.slide,
      this.state.bookDb,
      this.state.currentPage
    );
    this.fbook.current.pageFlip().flip(pageNumber, "top");
  }

  nextPageAnim() {
    this.onFirstPlay();

    LazyPageLoader.lazyLoader(
      this.state.currentPage + 1,
      FlipEventType.next,
      this.state.bookDb,
      this.state.currentPage
    );
    this.fbook.current.pageFlip().flipNext();
  }

  prevPageAnim() {
    this.onFirstPlay();

    LazyPageLoader.lazyLoader(
      this.state.currentPage - 1,
      FlipEventType.prev,
      this.state.bookDb,
      this.state.currentPage
    );
    this.fbook.current.pageFlip().flipPrev();
  }

  restart() {
    this.onFirstPlay();
    this.sound?.stop();

    LazyPageLoader.lazyLoader(
      0,
      FlipEventType.restart,
      this.state.bookDb,
      this.state.currentPage
    );

    setTimeout(() => {
      this.fbook.current.pageFlip().flip(0, "top");
    }, 0);
  }

  pageLoaded(page: any, image: HTMLImageElement | null) {
    if (image && image.width > 2) {
      page.loaded = true;
    }
  }

  private get navState(): NavState {
    return {
      book: this.props.book,
      currentPage: this.state.currentPage,
    };
  }

  private get progress(): Progress {
    return progress(this.navState);
  }

  private get canPlayAudio(): boolean {
    return (
      !!this.props.audioFileURL && this.props.book.type !== BookType.Regular
    );
  }

  render() {
    let minWidth = 315;
    if (isTablet) {
      // minWidth triggers 2-page view (if it can fit 2 * minWidth on the screen -> 2-page mode)
      // so tweak it a bit for tablets, to always show a single page in portrait mode and a
      // double-page in landscape
      const largest = Math.max(window.innerHeight, window.innerWidth);
      minWidth = largest / 2 - 10;
    }

    let scale = 1.0;
    if (isMobileOnly && isIOS && this.state.orientation == "landscape") {
      // iPhone messes up the landscape height, so force it to scale to prevents
      // pages from being cut off
      scale = 0.87;
    }

    return (
      <div className={"fbook-wrapper"} ref={this.container}>
        {!this.state.loaded && (
          <div style={{ width: "70px", height: "70px", margin: "0 auto" }}>
            <Loader type="Watch" color="#19c2f2" height={100} width={100} />
          </div>
        )}
        {this.state.loaded && (
          <div className="w-screen h-auto relative">
            <div style={{ transform: `scale(${scale})` }}>
              <Zoomable disabled={!isMobileOnly}>
                <FlipBook
                  width={window.innerWidth / 2}
                  height={
                    this.state.coverHeight *
                    (window.innerWidth / 2 / this.state.coverWidth)
                  }
                  size={"stretch"}
                  minWidth={minWidth}
                  maxWidth={this.state.coverWidth}
                  minHeight={100}
                  maxHeight={this.state.coverHeight}
                  showCover={true}
                  drawShadow={true}
                  maxShadowOpacity={0.45}
                  startZIndex={0}
                  onFlip={this.onFlip}
                  ref={this.fbook}
                  className={"wingzzz-flipbook mx-auto"}
                  startPage={
                    this.props.initialPage === 1 || this.props.initialPage === 0
                      ? 0
                      : this.props.initialPage
                  }
                  flippingTime={1000}
                  autoSize={true}
                  usePortrait={true}
                  mobileScrollSupport={false}
                  renderOnlyPageLengthChange={false}
                  useMouseEvents={!isMobileOnly}
                  swipeDistance={30}
                  clickEventForward={true}
                  showPageCorners={false}
                  disableFlipByClick={false}
                  bookDb={this.state.bookDb}
                  pageLoaded={this.pageLoaded}
                  epubDirectory={this.props.epubDirectory}
                  onChangeOrientation={this.onBookChangeOrientation}
                />
              </Zoomable>
            </div>
            <FlipControls
              currentPage={this.state.currentPage}
              totalPages={this.props.book.totalPages}
              nextPage={this.nextPageAnim}
              prevPage={this.prevPageAnim}
            />
            {this.canPlayAudio && (
              <BigAudioPlayButton
                hidden={!this.state.firstPlay || this.state.playing}
                onClick={this.onPlay}
              />
            )}
            <BottomToolbar
              currentPage={this.state.currentPage}
              totalPages={this.props.book.totalPages}
              restart={this.restart}
              sliderFlip={this.slideTo}
              finish={this.onFinish}
              fullscreen={
                (this.toggleFullscreen = this.toggleFullscreen.bind(this))
              }
              isFullscreen={this.state.fullscreen}
              canPlayAudio={this.canPlayAudio}
              playing={this.state.playing}
              onPlay={this.onPlay}
            />
          </div>
        )}
      </div>
    );
  }
}
